import React from 'react';

import { useTheme } from 'styled-components';
import Icon from './atomic/Icon';
import Typography from './atomic/Typography';
import { StyledFileMessage, StyledMessageImage } from './styled';
import { formatSizeUnits } from '../utils/formatSizeUnits';
import { IFileUploadResult, NameTypeIcon } from '../types';

interface IProps {
	file: IFileUploadResult,
	type: string,
}

const FileItem = (props: IProps) => {
	const { file, type } = props;

	const theme = useTheme();
	const transformedSize = formatSizeUnits(file.file_size || 0);
	const fileName = file.file_name?.split('.')[0];
	const fileType = file.file_name?.split('.')[1];
	const iconName = `type${fileType?.toUpperCase()}` as NameTypeIcon;

	if (type === 'images') {
		return (
			<StyledMessageImage url={file.url}>
				<StyledFileMessage href={file.url} target="_self" className="downloadElement" download>
					<Icon
						name="download"
						size={14}
						fill={theme.color.general.dark}
						wrapperColor={`${theme.color.general.lighter}b3`}
						borderRadius="50%"
					/>
				</StyledFileMessage>
			</StyledMessageImage>
		);
	}

	return (
		<StyledFileMessage
			href={file.url}
			target="_self"
		>
			<Icon name={iconName} />
			<div>
				<div className="nameContainer">
					<Typography
						variant="caption1"
						text={fileName}
						className="fileName"
					/>
					<Typography
						variant="caption1"
						text={`.${fileType}`}
					/>
				</div>
				<Typography
					className="downloadFile"
					variant="caption3"
					text={`Click to download   •   ${transformedSize}`}
				/>
			</div>
		</StyledFileMessage>
	);
};

export default FileItem;