import React from 'react';

import MessageItem from './MessageItem';
import ChatActionDivider from './ChatActionDivider';
import Rating from './Rating';
import IsTypingIndicator from './atomic/IsTypingIndicator';
import { ChatEvent, IChatEventBrief, IFileUploadResult } from '../types';

interface IProps {
	data: IChatEventBrief,
	operatorOnline: boolean,
	onScrollReplyMsg: (id: number) => void,
	onClickReply: (
		id: number,
		replyMsg: string,
		type: ChatEvent,
		file: IFileUploadResult | null,
		owner?: string
	) => void,
}

const ChatAction = (props: IProps) => {
	const {
		data, operatorOnline, onScrollReplyMsg, onClickReply,
	} = props;
	if (data?.type === 'operator_typing') {
		return <IsTypingIndicator />;
	}

	if (['contact_message', 'operator_message', 'system_message'].includes(data?.type)) {
		return (
			<MessageItem
				action={data}
				operatorOnline={operatorOnline}
				isSystemMessage={data.type === 'system_message'}
				onScrollReplyMsg={onScrollReplyMsg}
				onClickReply={onClickReply}
			/>
		);
	}

	if (data?.type === 'show_rating_options') {
		return (
			<Rating />
		);
	}

	return (
		<ChatActionDivider
			action={data}
		/>
	);
};

export default ChatAction;