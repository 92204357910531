import useWebSocket from 'react-use-websocket';
import { useEffect, useState } from 'react';
import platform from 'platform';
import ct from 'countries-and-timezones';

import { isValidJSON } from '../utils';
import { CHAT_EVENTS_TO_SHOW_IN_WIDGET, EnumLocalStorageKeys } from '../constants';
import { useLiveChat } from '../LiveChatProvider';
import { useWidget } from '../WidgetProvider';
import { getDeviceType } from '../utils/metadata';
import { IChatEventBrief, MessageDataStatus, WebsocketMessageData } from '../types';

export const WEBSOCKET_URL_START = process.env.REACT_APP_WS_HOST || 'ws://localhost:8000';

const getMessageData = (lastJsonMessage: string) => {
	let messageData;
	if (isValidJSON(lastJsonMessage)) {
		messageData = JSON.parse(lastJsonMessage);
	} else {
		messageData = lastJsonMessage;
	}

	return messageData;
};

export const useSendEventWebsocket = (
	onSend: (eventType: string) => void,
	onSuccess: (eventType: string, cb: () => void) => void,
) => {
	const {
		contactUid,
		license,
		chatStartedOrigin,
		chatStartedUrl,
		chatDetails,
		setChatDetails,
	} = useWidget();
	const {
		chatStarted,
		setChatStarted,
		preChatFormValues,
		setEventJustSent,
		setChatStatus,
	} = useLiveChat();

	const [eventSentStatus, setEventSentStatus] = useState<MessageDataStatus>('');
	const [eventInProgress, setEventInProgress] = useState<string>('');

	const {
		sendMessage,
		sendJsonMessage,
		lastJsonMessage,
		lastMessage,
		readyState,
	} = useWebSocket(
		`${WEBSOCKET_URL_START}/chat/contact?contact_uid=${contactUid}&license_id=${license}`,
		{
			share: true,
			shouldReconnect: () => chatStarted,
			reconnectInterval: 5000,
			reconnectAttempts: 50,
		},
		chatStarted,
	);

	const onSendEventChatStarted = (eventType: string) => {
		if (eventSentStatus !== 'inProgress') {
			setEventInProgress(eventType);
			setEventSentStatus('inProgress');
			if (chatDetails) {
				setChatDetails(null);
			}
			onSend(eventType);
		}
	};

	const getFormValuesTransformed = () => (
		Object.entries(preChatFormValues?.current?.contact || {}).reduce(
			(acc, [key, value]) => {
				if (value) {
					return {
						...acc,
						[key]: key === 'phone' ? `${value.replace(/\D/g, '')}` : value,
					};
				}
				return acc;
			},
			{},
		)
	);

	const trySendCustomSystemMessage = () => {
		try {
			const savedJson = localStorage.getItem(
				EnumLocalStorageKeys.systemMessages + chatStartedOrigin,
			);

			const saved: IChatEventBrief[] = savedJson ? JSON.parse(savedJson) : [];

			saved.forEach((item) => {
				sendJsonMessage(item);
			});

			localStorage.removeItem(
				EnumLocalStorageKeys.systemMessages + chatStartedOrigin,
			);
		} catch (e) {
			// empty error
		}
	};

	const onSendEvent = (eventType: string) => () => {
		if (chatStarted) {
			trySendCustomSystemMessage();
			onSendEventChatStarted(eventType);
		} else {
			setChatStarted(true);

			const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

			const formValuesTransformed: Record<string, string> = getFormValuesTransformed();

			if (preChatFormValues?.current?.department) {
				formValuesTransformed.department = preChatFormValues?.current?.department;
			}

			sendJsonMessage({
				type: 'chat_started',
				data: {
					origin: chatStartedUrl,
					contact: formValuesTransformed,
					metadata: {
						timezone,
						country: ct.getCountryForTimezone(timezone)?.id,
						browser: `${platform.name} ${platform.version}`,
						os: platform.os?.toString() || '',
						device: getDeviceType(),
						browserLanguage: window.navigator.language?.split('-')?.[0],
						cameFrom: 'live_chat',
					},
				},
			});

			trySendCustomSystemMessage();

			setEventSentStatus('inProgress');
			setEventInProgress('chat_started');
			setChatStatus('new');
		}
	};

	const onSuccessHandler = (messageData: WebsocketMessageData) => {
		if ((messageData?.status === 'ok' || messageData.status === 'operator_offline') && onSuccess) {
			onSuccess(eventInProgress, () => {
				setEventSentStatus('');
				setEventInProgress('');
				setEventJustSent(eventInProgress);
			});
		}
	};

	const messageDataHandler = (messageData: WebsocketMessageData) => {
		if (['ok', 'error', 'contact_connected'].includes(messageData.status || '')) {
			if (messageData.detail !== 'Only NEW or CLOSED chats can be started') {
				setEventSentStatus(messageData.status || '');
			}

			if (eventInProgress === 'chat_started') {
				setEventSentStatus('');
			}
		}
	};

	useEffect(() => {
		const messageData = getMessageData(lastJsonMessage as string);

		if (['contact_typing', 'contact_not_typing'].includes(messageData?.type)) {
			return;
		}

		if (messageData?.status) {
			if (messageData.status === 'error') {
				setEventInProgress('');
			}

			messageDataHandler(messageData);
			onSuccessHandler(messageData);
		}
	}, [eventInProgress, lastMessage]);

	return {
		sendEventToServer: sendJsonMessage,
		sendMessage,
		eventInProgress,
		setEventInProgress,
		eventSentStatus,
		setEventSentStatus,
		onSendEvent,
		readyState,
	};
};

export const useWidgetReceivingWebsocket = () => {
	const [sound] = useState(
		() => new Audio('https://goodzyk-crm.s3.eu-west-2.amazonaws.com/skibble/new_message_open.mp3'),
	);

	const { contactUid, license } = useWidget();
	const {
		chatStarted,
		setHistoryEvent,
		setHistory,
		setOperatorName,
		setOperatorPic,
		setChatStarted,
		setOperatorOnline,
		isSoundOn,
		setIsAllMessagesSeen,
		setEventJustReceived,
		setReplyMessageId,
	} = useLiveChat();

	const { lastJsonMessage, lastMessage } = useWebSocket(
		`${WEBSOCKET_URL_START}/chat/contact?contact_uid=${contactUid}&license_id=${license}`,
		{
			share: true,
			onError() {},
			shouldReconnect: () => chatStarted,
			reconnectInterval: 5000,
			reconnectAttempts: 50,
		},
		chatStarted,
	);

	const isOperatorStatus = (messageData: WebsocketMessageData) => (
		messageData?.status === 'operator_offline' || messageData?.status === 'operator_online'
	);

	const getIsStatusNotOk = (messageData: WebsocketMessageData) => (
		messageData?.type
		&& messageData?.status !== 'ok'
		&& CHAT_EVENTS_TO_SHOW_IN_WIDGET.includes(messageData.type)
	);

	const getIsSound = (messageData: WebsocketMessageData) => (
		messageData?.type !== 'operator_typing'
		&& isSoundOn
		&& document.activeElement?.id !== 'liveChatTextarea'
		&& messageData?.type
		&& CHAT_EVENTS_TO_SHOW_IN_WIDGET.includes(messageData.type)
	);

	useEffect(() => {
		let messageData;
		if (isValidJSON(lastJsonMessage as string)) {
			messageData = JSON.parse(lastJsonMessage as string);
		} else {
			messageData = lastJsonMessage;
		}

		if (messageData?.type === 'operator_not_typing') {
			setHistory((prev) => prev.filter((item) => item.type !== 'operator_typing'));
			return;
		}

		if (messageData?.type === 'get_channel_message_id') {
			setReplyMessageId(messageData.channel_message_id);
		}

		if (isOperatorStatus(messageData)) {
			setOperatorOnline(messageData.status === 'operator_online');
		}

		// receive messages from server
		if (getIsStatusNotOk(messageData)) {
			setHistoryEvent(messageData);
			setEventJustReceived(messageData.type);

			if (messageData?.type !== 'operator_typing') {
				setIsAllMessagesSeen(false);
			}

			if (getIsSound(messageData)) {
				sound.play();
			}

			if (messageData?.type === 'chat_closed') {
				setChatStarted(false);
			} else if (messageData?.type === 'operator_joined') {
				const operatorName = messageData.operator_name || '';
				const operatorPic = messageData.operator_pic || '';
				setOperatorName(operatorName);
				setOperatorPic(operatorPic);
				setOperatorOnline(true);
			}
		}
	}, [sound, lastMessage]);
};